<!-- src/views/Admin.vue -->
<template>
  <div class="in_houce">
    <!-- 設定 -->
    <div class="menuCategory">
      <span class="menuFloor_inner">
        <button type="button" @click="clickSetting" value="setting" class="buttonBasic boxLeft" title="設定">
          <span v-if="settingMode === 0">
            <img :src="setOpne" alt="設定" width="30">
            <label style="margin-left:6px;">設定</label>
          </span>
          <span v-if="settingMode === 1">
            <img :src="xImage" alt="設定を閉じる" width="30">
            <label style="margin-left:6px;">閉じる</label>
          </span>
        </button>
      </span>
    </div>

    <div v-if="settingMode === 0">
      <AdminCleaningComponent />
    </div>
    <div v-if="settingMode === 1">
      <AdminPasswordComponent />
    </div>
  </div>
</template>
<script>
import AdminPasswordComponent from '@/components/AdminPasswordComponent'
import AdminCleaningComponent from '@/components/AdminCleaningComponent'
import setOpneImage from '@/assets/set.png'
import clauseImage from '@/assets/clause.png'
export default {
  name: 'AdminPage',
  components: {
    AdminPasswordComponent,
    AdminCleaningComponent
  },
  data: function () {
    return {
      setOpne: setOpneImage, // 設定アイコン画像
      xImage: clauseImage, // 閉じるアイコン画像
      settingMode: 0 // 設定表示切替フラグ 0は設定非表示、1は設定表示
    }
  },
  mounted () {},
  methods: {
    // 設定ボタン
    clickSetting: function () {
      var value = 0
      if (this.settingMode === 0) {
        value = 1
      } else {
        value = 0
      }
      this.settingMode = value
    }
  }
}
</script>
