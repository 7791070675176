/* eslint-disable no-unused-vars */
const apiUrlLists = { // 本番用APIのURL
  getConfig: 'https://restrooms-api.hisapps.com/get-config',
  getOptions: 'https://restrooms-api.hisapps.com/get-option',
  dlPrivateRoom: 'https://restrooms-api.hisapps.com/get-room',
  getAllRoom: 'https://restrooms-api.hisapps.com/get-room',
  cleaning: 'https://restrooms-api.hisapps.com/change-status-cleaning',
  sendPassword: 'https://restrooms-api.hisapps.com/password-authentication',
  malfunction: 'https://restrooms-api.hisapps.com/change-status-malfunction',
  changeConfig: 'https://restrooms-api.hisapps.com/change-config',
  changePass: 'https://restrooms-api.hisapps.com/change-password',
  testUsing: 'https://restrooms-api.hisapps.com/change-status-using'
}
const demoApiUrlLists = { // テスト環境のAPIのURL
  getConfig: 'https://restrooms-api.demo.hisapps.com/get-config',
  getOptions: 'https://restrooms-api.demo.hisapps.com/get-option',
  dlPrivateRoom: 'https://restrooms-api.demo.hisapps.com/get-room',
  getAllRoom: 'https://restrooms-api.demo.hisapps.com/get-room',
  cleaning: 'https://restrooms-api.demo.hisapps.com/change-status-cleaning',
  sendPassword: 'https://restrooms-api.demo.hisapps.com/password-authentication',
  malfunction: 'https://restrooms-api.demo.hisapps.com/change-status-malfunction',
  changeConfig: 'https://restrooms-api.demo.hisapps.com/change-config',
  changePass: 'https://restrooms-api.demo.hisapps.com/change-password',
  testUsing: 'https://restrooms-api.demo.hisapps.com/change-status-using'
}
/* eslint-enable no-unused-vars */
// export const apiUrls = apiUrlLists // 本番用APIURL
export const apiUrls = demoApiUrlLists // 開発用APIURL
export const demoUrl = 'https://restrooms.demo.hisapps.com' // 開発時の画像表示用URL
export const apiHeaderName = 'X-His-Restrooms' // カスタムヘッダ名
export const autoReloadOptions = [ // 自動再読み込み選択肢
  { label: 'しない', interval: 0 },
  { label: '5秒', interval: 5000 },
  { label: '10秒', interval: 10000 },
  { label: '30秒', interval: 30000 },
  { label: '1分', interval: 60000 },
  { label: '5分', interval: 300000 },
  { label: '10分', interval: 600000 }
]
export const cookieParamNameLists = [ // クッキーのkey名
  'showConfirmation', // 個別表示フラグで、cookieではなくconfigで利用
  'autoReload', // 自動再読み込み間隔
  'floor', // 選択中階
  'kinds', // 選択中種類
  'showAll', // 表示方法、状況指定か全階
  'showSignage' // サイネージページの表示方法、全階か個室か
]
export const storagePeriodNum = 365 // クッキーの保存期間
