<template>
  <!-- リロード -->
  <div class="container">
    <button type="button" @click="clickReload" value="setting" class="buttonBasic boxRight" id="re" title="再読み込み">
      <img :src="reload" alt="再読み込み" width="30" style="margin-top: -6px;">
      <span>再読み込み</span>
    </button>

    <div v-if="setMsg != ''" class="messageBoxDone" style="margin-top: 10px;">
      <label>{{setMsg}}</label>
    </div>
  </div>

  <!-- 処理中イメージ表示 -->
  <div v-if="loading === 1" style="text-align: center;">
    <img :src="nowLoading" alt="処理中イメージ" width="300">
  </div>

  <div v-if="loading === 0">
    <div v-for="(floor, i) in floorData" :key="i" class="container">
      <section v-for="(kinds, j) in kindsData" :key="j" class="roomWrap">
        <span v-show="allRoom[i][j].length > 0">
          <h2 class="ttl_line">{{floor.label}}／{{kinds.label}}</h2>
          <ul class="roomBox">
            <li v-for="(room, k) in allRoom[i][j]" :key="k" class="roomBox_item" style="text-align: center; align-items: center;">
              <label :class="room.malfunction === true ? 'status_repair' : 'status_unused'">
                <div class="roomBox_icon" style="margin: 10px 0px 20px 0px;">
                  <span class="material-symbols-outlined">
                    <img :src="imgUrl + room.img" :alt="room.label" class="icon_set">
                  </span>
                </div>
              </label>
              <div class="roomBox_name"><p>{{room.label}}</p></div>
              <button v-if="room.malfunction == false" @click="clickMalfunction(i, j, k)" class="buttonRepair">故障登録</button>
              <button v-if="room.malfunction == true" @click="clickMalfunction(i, j, k)" class="buttonRepair">使用可登録</button>
            </li>
          </ul>
        </span>
      </section>
    </div>
  </div>
</template>

<script>
import reImage from '@/assets/re.png'
import loadingImage from '@/assets/nowLoading.gif'
import {
  apiUrls,
  demoUrl,
  apiHeaderName
} from '@/publicVariableList.js'
export default {
  name: 'AdminSettingRoomComponent',
  data: function () {
    return {
      apiUrl: apiUrls, // APIのURL
      reload: reImage, // 再読み込みアイコン画像
      nowLoading: loadingImage, // ローディングイメージ
      floorOption: [], // 階数選択肢のlabel(selectbox用)
      floorData: [], // 階数選択肢の全データ
      kindsOption: [], // 種類選択肢のlabel(selectbox用)
      kindsImage: [], // 種類選択肢の画像リンク
      kindsData: [], // 種類選択肢の全データ
      allRoom: [], // 個室データ
      selectedFloor: '', // 階数の選択結果
      selectedKinds: '', // 種類の選択結果
      malfunctionLabel: '故障中', // 故障中に表示する
      nowCleanings: [], // 清掃中フラグ配列 清掃中フラグ 0は使用可能、1清掃中
      showCleaningBtn: [], // 清掃ボタン表示フラグ配列 フラグ 0は非表示、1は表示
      showErrors: [], // 検索結果０件時のエラー表示フラグ配列 0はエラー無、1はエラー有
      setMsg: '', // ステータス更新時のメッセージ
      loading: 0, // 処理中フラグ 0は非表示、1は表示
      imgUrl: '', // s3の画像表示用URL
      apiHeaderStr: '' // API実行時に追加するヘッダーの中身
    }
  },
  mounted () {
    // 画面初期表示時に実行
    this.apiHeaderStr = btoa('AdminSettingRoom')
    this.imgUrl = location.protocol + '//' + location.host
    // テスト用
    if (this.imgUrl.includes('localhost')) {
      this.imgUrl = demoUrl
    }
    this.getOptions()
  },
  methods: {
    // 階数とトイレ種類の選択肢を取得
    getOptions: function () {
      // const url = 'https://eb9b1gt3ri.execute-api.ap-northeast-1.amazonaws.com/dev/private_room_confirmation_get_option_resource'
      const url = this.apiUrl.getOptions
      const customHeader = { key: apiHeaderName, value: this.apiHeaderStr }
      function fetchData (url, customHeader) {
        return new Promise((resolve, reject) => {
          const req = new XMLHttpRequest()
          req.open('GET', url)
          req.setRequestHeader(customHeader.key, customHeader.value)
          req.onload = function () {
            if (req.status === 200) {
              const responseData = JSON.parse(req.responseText)
              resolve(responseData)
            } else {
              console.error('Request failed. Status:', req.status)
              reject(new Error())
            }
          }
          req.send()
        })
      }
      const promises = [fetchData(url, customHeader)]
      Promise.all(promises).then(data => {
        this.makeFloorOption(data[0].floorData)
        this.makeKindsOption(data[0].kindsData)
        // 個室データ取得
        this.getAllData()
      }).catch(error => { console.error('Error:', error) })
    },
    // 階数選択肢を作成
    makeFloorOption: function (data) {
      var floorTmp = []
      for (var i = 0; i < data.length; i++) {
        floorTmp[i] = data[i].label
      }
      this.floorData = data
      this.floorOption = floorTmp
    },
    // 種類選択肢を作成
    makeKindsOption: function (data) {
      var kindsTmp = []
      var imgTmp = []
      for (var i = 0; i < data.length; i++) {
        kindsTmp[i] = data[i].label
        imgTmp[i] = data[i].img
      }
      this.kindsData = data
      this.kindsOption = kindsTmp
      this.kindsImage = imgTmp
    },

    // 全データ取得
    getAllData: function () {
      this.allRoom = []
      this.nowCleanings = []
      this.showCleaningBtn = []
      this.showErrors = []
      for (var i = 0; i < (this.floorOption).length; i++) {
        this.allRoom[i] = []
        this.nowCleanings[i] = 0
        this.showCleaningBtn[i] = 0
        this.showErrors[i] = []
        for (var j = 0; j < (this.kindsOption).length; j++) {
          this.allRoom[i][j] = []
          this.showErrors[i][j] = 0
          this.getAllRoom(this.floorOption[i], this.kindsOption[j], i, j)
        }
      }
      // console.log(this.allRoom)
    },

    // 個室データ取得
    getAllRoom: function (selectedFloor, selectedKinds, i, j) {
      const floorId = this.searchOptionId(this.floorData, selectedFloor)
      const kindsId = this.searchOptionId(this.kindsData, selectedKinds)
      const param = '?floor=' + floorId + '&kinds=' + kindsId
      // データ取得
      // const url = 'https://eb9b1gt3ri.execute-api.ap-northeast-1.amazonaws.com/dev/private_room_confirmation_get_room_resource'
      const url = this.apiUrl.getAllRoom
      const customHeader = { key: apiHeaderName, value: this.apiHeaderStr }
      function fetchData (url, customHeader) {
        return new Promise((resolve, reject) => {
          const req = new XMLHttpRequest()
          req.open('GET', url + param)
          req.setRequestHeader(customHeader.key, customHeader.value)
          req.onload = function () {
            if (req.status === 200) {
              const responseData = JSON.parse(req.responseText)
              resolve(responseData)
            } else {
              console.error('Request failed. Status:', req.status)
              reject(new Error())
            }
          }
          req.send()
        })
      }
      const promises = [fetchData(url, customHeader)]
      Promise.all(promises).then(data => {
        this.allRoom[i][j] = data[0]
        if ((this.allRoom[i][j]).length === 0) {
          this.showErrors[i][j] = 1
        } else {
          // 清掃中フラグ
          this.setCleaningFlg(this.allRoom[i][j], i)
          this.showCleaningBtn[i] = 1
        }
      }).catch(error => { console.error('Error:', error) })
    },
    searchOptionId: function (data, label) {
      var id = ''
      for (var i = 0; i < data.length; i++) {
        if (data[i].label === label) {
          id = data[i].data_id
        }
      }
      return id
    },
    // 個室データに対して清掃中かどうかの判定
    setCleaningFlg (privateRoom, i) {
      // 1件でも清掃フラグがあれば清掃中にする
      var Cleaning = 0
      var prLength = privateRoom.length
      if (prLength >= 1) {
        for (var j = 0; j < prLength; j++) {
          if (privateRoom[j].cleaning === true) {
            Cleaning = 1
          }
        }
      }
      this.nowCleanings[i] = Cleaning
    },

    // リロードボタン
    clickReload: function () {
      this.setMsg = ''
      this.getOptions()
    },

    // 故障登録、使用可登録ボタン
    clickMalfunction: function (i, j, k) {
      this.loading = 1
      this.setMsg = ''
      var setStatus = ''
      var malfunction = false
      if (this.allRoom[i][j][k].malfunction === false) {
        malfunction = true
        setStatus = '故障登録'
      } else {
        malfunction = false
        setStatus = '使用可登録'
      }
      const params = {
        data_id: this.allRoom[i][j][k].data_id,
        status: malfunction
      }
      // const url = 'https://eb9b1gt3ri.execute-api.ap-northeast-1.amazonaws.com/dev/private_room_confirmation_change_status_malfunction_resource'
      const url = this.apiUrl.malfunction
      const customHeader = { key: apiHeaderName, value: this.apiHeaderStr }
      function fetchData (url, customHeader) {
        return new Promise((resolve, reject) => {
          const req = new XMLHttpRequest()
          req.open('POST', url)
          req.setRequestHeader(customHeader.key, customHeader.value)
          req.onload = function () {
            if (req.status >= 200 && req.status < 300) {
              const responseData = JSON.parse(req.responseText)
              resolve(responseData)
            } else {
              console.error('Request failed. Status:', req.status)
              reject(new Error())
            }
          }
          req.send(JSON.stringify(params))
        })
      }
      const promises = [fetchData(url, customHeader)]
      Promise.all(promises).then(data => {
        this.allRoom[i][j][k].malfunction = data[0].malfunction
        this.setMsg = this.floorOption[i] + this.kindsOption[j] + 'の 「' + this.allRoom[i][j][k].label + '」を' + setStatus + 'しました'
        this.loading = 0
      }).catch(error => { console.error('Error:', error) })
    }
  }
}
</script>
