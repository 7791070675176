// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import AdminPage from '@/views/AdminPage.vue'
import ConfirmationPage from '@/views/ConfirmationPage.vue'
import SignagePage from '@/views/SignagePage.vue'

const routes = [
  { // ページが指定されていない場合、自動的に'/confirmation-page'へリダイレクト
    path: '/',
    redirect: '/confirmation-page'
  },
  {
    path: '/admin-page',
    name: 'admin-page',
    component: AdminPage
  },
  {
    path: '/confirmation-page',
    name: 'confirmation-page',
    component: ConfirmationPage
  },
  {
    path: '/signage-page',
    name: 'signage-page',
    component: SignagePage
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
