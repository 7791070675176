<template>
  <div class="container">
  <!-- main -->
  <main class="mainContainer">
    <div v-for="(rooms, i) in allRoom" :key="i">
      <div class="common_inner">
        <section class="roomWrap">
          <div class="roomCompartment">
            <ul class="roomBox">
              <li class="roomBox_item roomBox_floor">
                <h2>{{ floorOption[i] }}</h2>
              </li>
              <li v-for="(room, j) in rooms" :key="j" class="roomBox_item" :class="{'status_using': nowCleanings[i][j] === 0 && noVacancy[i][j] === 1,'status_cleaning': nowCleanings[i][j] === 1,'roomAccessible': kindsData[j].data_id === '5','roomWoman': kindsData[j].data_id === '4','roomMan': kindsData[j].data_id === '3'}" >
              <div class="roomBox_image">
                <img :src="getImage(kindsData[j].data_id)" :alt="kindsData[j].label">
              </div>
              <div class="roomBox_status">
                <p class="Box_status">
                  <span v-if="nowCleanings[i][j] === 1">清掃中</span>
                  <span v-else-if="nowCleanings[i][j] === 0 && noVacancy[i][j] === 1">満</span>
                  <span v-else>空</span>
                </p>
                <p class="Box_count">{{ unused[i][j] }}</p>
              </div>
            </li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  </main>

  <!-- リロード -->
  <div class="reload">
    <div class="timeWrap common_inner">
      <div>
        <button type="button" @click="clickReload" value="setting" class="buttonReload" id="re" title="再読み込み">
          <img :src="reload" alt="再読み込み" srcset="">
        </button>
      </div>
      <div class="showLastDateTime">{{lastDateTime}}</div>
    </div>
  </div>
  </div>
</template>

<script>
import reImage from '@/assets/reload.svg'
import clauseImage from '@/assets/clause.png'
import accessibleImage from '@/assets/accessible.svg'
import manImage from '@/assets/man.svg'
import womanImage from '@/assets/woman.svg'
import {
  apiUrls,
  autoReloadOptions,
  cookieParamNameLists,
  storagePeriodNum,
  demoUrl,
  apiHeaderName
} from '@/publicVariableList.js'
export default {
  name: 'SignageTypeComponent',
  data: function () {
    return {
      apiUrl: apiUrls, // APIのURL
      xImage: clauseImage, // 閉じるアイコン画像
      reload: reImage, // 再読み込みアイコン画像
      accessible: accessibleImage, // みんなのトイレアイコン画像
      man: manImage, // 男性トイレアイコン画像
      woman: womanImage, // 女性トイレアイコン画像
      floorOption: [], // 階数選択肢のlabel(selectbox用)
      floorData: [], // 階数選択肢の全データ
      kindsOption: [], // 種類選択肢のlabel(selectbox用)
      kindsImage: [], // 種類選択肢の画像リンク
      kindsData: [], // 種類選択肢の全データ
      allRoom: [], // 個室データ
      selectedFloor: '', // 階数の選択結果
      selectedKinds: '', // 種類の選択結果
      nowCleanings: [], // 清掃中フラグ配列 清掃中フラグ 0は使用可能、1清掃中
      noVacancy: [], // 満室フラグ配列 満室フラグ 0は使用可能、1は満室
      unused: [], // 使用可能な件数配列
      showErrors: [], // 検索結果０件時のエラー表示フラグ配列 0はエラー無、1はエラー有
      autoReloadOption: autoReloadOptions, // 自動再読み込み選択肢
      selectedAutoReload: 0, // 自動再読み込み選択結果
      storagePeriod: storagePeriodNum, // クッキーの保存期間
      cookieParamNames: cookieParamNameLists, // クッキーのkey名
      lastDateTime: '', // 最終更新日時
      imgUrl: '', // s3の画像表示用URL
      apiHeaderStr: '', // API実行時に追加するヘッダーの中身
      intervalId: null
    }
  },
  mounted () {
    // 画面初期表示時に実行
    this.apiHeaderStr = btoa('ConfirmationAll')
    this.imgUrl = location.protocol + '//' + location.host
    // テスト用
    if (this.imgUrl.includes('localhost')) {
      this.imgUrl = demoUrl
    }
    this.getOptions()
    document.body.classList.add('bgBlack')
  },
  beforeUnmount () {
    // compornent破棄時に実行
    this.autoReloadStop()
  },
  methods: {
    // 階数とトイレ種類の選択肢を取得
    getOptions: function () {
      // const url = 'https://eb9b1gt3ri.execute-api.ap-northeast-1.amazonaws.com/dev/private_room_confirmation_get_option_resource'
      const url = this.apiUrl.getOptions
      const customHeader = { key: apiHeaderName, value: this.apiHeaderStr }
      function fetchData (url, customHeader) {
        return new Promise((resolve, reject) => {
          const req = new XMLHttpRequest()
          req.open('GET', url)
          req.setRequestHeader(customHeader.key, customHeader.value)
          req.onload = function () {
            if (req.status === 200) {
              const responseData = JSON.parse(req.responseText)
              resolve(responseData)
            } else {
              console.error('Request failed. Status:', req.status)
              reject(new Error())
            }
          }
          req.send()
        })
      }
      const promises = [fetchData(url, customHeader)]
      Promise.all(promises).then(data => {
        this.makeFloorOption(data[0].floorData)
        this.makeKindsOption(data[0].kindsData)
        // 個室データ取得
        this.getAllData()
        this.callAutoReloader()
      }).catch(error => { console.error('Error:', error) })
    },
    // 階数選択肢を作成
    makeFloorOption: function (data) {
      var floorTmp = []
      for (var i = 0; i < data.length; i++) {
        floorTmp[i] = data[i].label
      }
      this.floorData = data.slice().reverse()
      this.floorOption = floorTmp.slice().reverse()
    },
    // 種類選択肢を作成
    makeKindsOption: function (data) {
      var kindsTmp = []
      var imgTmp = []
      // テレキューブは除く
      data = data.filter(item => item.data_id !== '6').reverse()
      for (var i = 0; i < data.length; i++) {
        kindsTmp[i] = data[i].label
        imgTmp[i] = data[i].img
      }
      this.kindsData = data
      this.kindsOption = kindsTmp
      this.kindsImage = imgTmp
    },

    // 全データ取得
    getAllData: function () {
      this.allRoom = []
      this.nowCleanings = []
      this.noVacancy = []
      this.unused = []
      this.showErrors = []
      for (var i = 0; i < (this.floorOption).length; i++) {
        this.allRoom[i] = []
        this.nowCleanings[i] = []
        this.noVacancy[i] = []
        this.unused[i] = []
        this.showErrors[i] = []
        for (var j = 0; j < (this.kindsOption).length; j++) {
          this.showErrors[i][j] = 0
          this.nowCleanings[i][j] = 0
          this.noVacancy[i][j] = 0
          this.unused[i][j] = ''
          this.getAllRoom(this.kindsOption[j], this.floorOption[i], i, j)
        }
      }
      this.getLastDateTime()
    },
    // 簡易版全データ取得
    simpleGetAllData: function () {
      for (var i = 0; i < (this.floorOption).length; i++) {
        for (var j = 0; j < (this.kindsOption).length; j++) {
          this.getAllRoom(this.kindsOption[j], this.floorOption[i], i, j)
        }
      }
      this.getLastDateTime()
      // console.log(JSON.stringify(this.allRoom))
    },

    // 個室データ取得
    getAllRoom: function (selectedKinds, selectedFloor, i, j) {
      // console.log(selectedFloor + '/' + selectedKinds)
      const kindsId = this.searchOptionId(this.kindsData, selectedKinds)
      const floorId = this.searchOptionId(this.floorData, selectedFloor)
      const param = '?floor=' + floorId + '&kinds=' + kindsId
      // データ取得
      const url = this.apiUrl.getAllRoom
      const customHeader = { key: apiHeaderName, value: this.apiHeaderStr }
      function fetchData (url, customHeader) {
        return new Promise((resolve, reject) => {
          const req = new XMLHttpRequest()
          req.open('GET', url + param)
          req.setRequestHeader(customHeader.key, customHeader.value)
          req.onload = function () {
            if (req.status === 200) {
              const responseData = JSON.parse(req.responseText)
              resolve(responseData)
            } else {
              console.error('Request failed. Status:', req.status)
              reject(new Error())
            }
          }
          req.send()
        })
      }
      const promises = [fetchData(url, customHeader)]
      Promise.all(promises).then(data => {
        if (data[0].length !== 0) {
          // 自動再読み込み時に画面のちらつきを防ぐため、データが前回と一致しているかを確認
          var updateFlg = this.checkData(this.allRoom[i][j], data[0])
          if (updateFlg === 1) {
            this.allRoom[i][j] = data[0]
            // 清掃中フラグ
            this.setCleaningFlg(this.allRoom[i][j], i, j)
            // 満室フラグ
            this.setNoVacancyFlg(this.allRoom[i][j], i, j)
          }
        }
      }).catch(error => { console.error('Error:', error) })
    },
    searchOptionId: function (data, label) {
      var id = ''
      for (var i = 0; i < data.length; i++) {
        if (data[i].label === label) {
          id = data[i].data_id
        }
      }
      return id
    },
    // 自動再読み込み時に画面のちらつきを防ぐため、データが前回と一致しているかを確認
    checkData: function (nowData, newData) {
      var updateFlg = 0
      var keys = []
      if (nowData) {
        if (nowData.length === 0) {
          updateFlg = 1
        } else if (nowData.length !== newData.length) {
          updateFlg = 1
        } else {
          for (var i = 0; i < nowData.length; i++) {
            keys = Object.keys(nowData[i])
            for (var j = 0; j < keys.length; j++) {
              if (nowData[i][keys[j]] !== newData[i][keys[j]]) {
                updateFlg = 1
              }
            }
          }
        }
      } else {
        updateFlg = 1
      }
      return updateFlg
    },
    // 個室データに対して清掃中かどうかの判定
    setCleaningFlg (privateRoom, i, j) {
      // 1件でも清掃フラグがあれば清掃中にする
      var Cleaning = 0
      var prLength = privateRoom.length
      if (prLength >= 1) {
        for (var k = 0; k < prLength; k++) {
          if (privateRoom[k].cleaning === true) {
            Cleaning = 1
          }
        }
      }
      this.nowCleanings[i][j] = Cleaning
    },
    // 個室データに対して満室中かどうかの判定
    setNoVacancyFlg (privateRoom, i, j) {
      // 1件でも未使用ならフラグを立てない
      var noVacancy = 1
      var prLength = privateRoom.length
      var useCount = 0
      if (prLength >= 1) {
        for (var k = 0; k < prLength; k++) {
          if (privateRoom[k].now_using === false) {
            if (privateRoom[k].malfunction === false) {
              useCount += 1
              noVacancy = 0
            }
          }
        }
      }
      this.noVacancy[i][j] = noVacancy
      this.unused[i][j] = '残り' + useCount + '/' + prLength
    },

    // リロードボタン
    clickReload: function () {
      location.reload()
    },
    // リロード
    reloader: function () {
      this.simpleGetAllData()
    },

    // 自動再仰臥実行
    callAutoReloader: function () {
      this.autoReloadStop()
      this.autoReloader()
    },
    // 自動再読み込み(5秒間隔)
    autoReloader: function () {
      var num = 5000
      if (num > 0) {
        this.intervalId = setInterval(() => {
          this.reloader()
        }, num)
      }
    },
    // 自動再読み込み停止
    autoReloadStop: function () {
      clearInterval(this.intervalId)
    },
    // 最終更新日時取得
    getLastDateTime: function () {
      const now = new Date()
      const year = now.getFullYear()
      const month = (now.getMonth() + 1).toString().padStart(2, '0')
      const day = (now.getDate()).toString().padStart(2, '0')
      const hours = (now.getHours()).toString().padStart(2, '0')
      const minutes = (now.getMinutes()).toString().padStart(2, '0')
      const seconds = (now.getSeconds()).toString().padStart(2, '0')
      const str = '最終更新: '

      // フォーマットを整える
      this.lastDateTime = `${str} ${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    },
    // トイレ種別に対応する画像取得
    getImage (dataId) {
      if (dataId === '5') {
        return this.accessible
      } else if (dataId === '4') {
        return this.woman
      } else if (dataId === '3') {
        return this.man
      }
      return '' // 該当しない場合は空文字列
    }
  }
}
</script>
