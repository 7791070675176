<template>
  <div v-if="showAll === 1" width="100%">
    <ConfirmationAllComponent />
  </div>
  <div v-if="showAll === 0">
    <ConfirmationSomeComponent />
  </div>

  <div class="container lastCompornents" >
    <section>
      <h2 class="ttl_box">表示切替</h2>
      <p v-if="showAll === 0">現在、条件指定表示になっています。<br>全階を一度に確認するか、種類と階を指定して確認する表示を切り替えます。</p>
      <p v-if="showAll === 1">現在、全階表示になっています。<br>全階を一度に確認するか、種類と階を指定して確認する表示を切り替えます。</p>
      <div>
        <button v-if="showAll === 0" @click="setShowAll()" class="buttonPost">全階表示</button>
        <button v-if="showAll === 1" @click="setShowAll()" class="buttonPost">条件指定表示</button>
      </div>
    </section>
  </div>
</template>

<script>
import ConfirmationAllComponent from '@/components/ConfirmationAllComponent'
import ConfirmationSomeComponent from '@/components/ConfirmationSomeComponent'
import {
  cookieParamNameLists,
  storagePeriodNum
} from '@/publicVariableList.js'
export default {
  name: 'ConfirmationComponent',
  components: {
    ConfirmationAllComponent,
    ConfirmationSomeComponent
  },
  data: function () {
    return {
      storagePeriod: storagePeriodNum, // クッキーの保存期間
      showAll: 99, // 表示切替フラグ 0は条件指定表示、1は全階表示 0や1をここで指定すると画面表示時に非同期処理が走るため、関係ない数値をセット
      cookieParamNames: cookieParamNameLists // クッキーのkey名
    }
  },
  mounted () {
    // 画面初期表示時に実行
    this.showCookie()
  },
  methods: {
    // クッキー新規作成
    addCookie: function (key, val) {
      const expirationDate = new Date()
      expirationDate.setDate(expirationDate.getDate() + this.storagePeriod)
      document.cookie = key + '=' + val + '; expires=' + expirationDate.toUTCString()
    },
    // クッキー確認
    showCookie: function () {
      const cookies = document.cookie.split('; ')
      var data = []
      for (const cookie of cookies) {
        // const [key, value] = cookie.split('=')
        data = cookie.split('=')
        // 表示切替の場合
        if (this.cookieParamNames[4] === data[0]) {
          this.showAll = Number(data[1])
        }
        // console.log(data[0] + ': ' + data[1])
      }
      if (this.showAll !== 0 && this.showAll !== 1) {
        // クッキーがなくてどちらも未選択の場合にデフォルトを１につする
        this.showAll = 1
      }
    },

    // 全階表示と条件指定表示の切り替え
    setShowAll: function () {
      var value = 0
      if (this.showAll === 0) {
        value = 1
      } else {
        value = 0
      }
      this.showAll = value
      this.addCookie(
        this.cookieParamNames[4],
        value
      )
    }
  }
}
</script>
