<template>
  <div v-if="showAll === 1" width="100%" class="signage_type">
    <!-- 全館表示 -->
    <header class="header">
      <!-- タイトルクリックで全館/個室表示切り替え可能 -->
      <h1 class="pageTitle"><span @click="setShowAll()">化粧室の空き状況</span></h1>
    </header>
    <SignageTypeComponent />
  </div>
  <div v-if="showAll === 0"  class="signage_floor">
    <!-- 個室表示 -->
    <header class="header">
      <!-- タイトルクリックで全館/個室表示切り替え可能 -->
      <h1 class="pageTitle"><span @click="setShowAll()">化粧室の空き状況</span></h1>
    </header>
    <SignageFloorComponent />
  </div>
  <div class="container lastCompornents" >
    <section>
      <div>
      </div>
    </section>
  </div>
</template>

<script>
import SignageTypeComponent from '@/components/SignageTypeComponent'
import SignageFloorComponent from '@/components/SignageFloorComponent'
import {
  cookieParamNameLists,
  storagePeriodNum
} from '@/publicVariableList.js'
export default {
  name: 'SignageComponent',
  components: {
    SignageTypeComponent,
    SignageFloorComponent
  },
  data: function () {
    return {
      storagePeriod: storagePeriodNum, // クッキーの保存期間
      showAll: 99, // 表示切替フラグ 0は個室表示、1は全館表示 0や1をここで指定すると画面表示時に非同期処理が走るため、関係ない数値をセット
      cookieParamNames: cookieParamNameLists // クッキーのkey名
    }
  },
  mounted () {
    // 画面初期表示時に実行
    this.showCookie()
  },
  methods: {
    // クッキー新規作成
    addCookie: function (key, val) {
      const expirationDate = new Date()
      expirationDate.setDate(expirationDate.getDate() + this.storagePeriod)
      document.cookie = key + '=' + val + '; expires=' + expirationDate.toUTCString()
    },
    // クッキー確認
    showCookie: function () {
      const cookies = document.cookie.split('; ')
      var data = []
      for (const cookie of cookies) {
        // const [key, value] = cookie.split('=')
        data = cookie.split('=')
        // 表示切替の場合
        if (this.cookieParamNames[5] === data[0]) {
          this.showAll = Number(data[1])
        }
        // console.log(data[0] + ': ' + data[1])
      }
      if (this.showAll !== 0 && this.showAll !== 1) {
        // クッキーがなくてどちらも未選択の場合にデフォルトを１につする
        this.showAll = 1
      }
    },

    // 全階表示と個室表示の切り替え(bodyの背景色も切り替える)
    setShowAll: function () {
      var value = 0
      if (this.showAll === 0) {
        value = 1
        document.body.classList.remove('bgWhite')
        document.body.classList.add('bgBlack')
      } else {
        value = 0
        document.body.classList.remove('bgBlack')
        document.body.classList.add('bgWhite')
      }
      this.showAll = value
      this.addCookie(
        this.cookieParamNames[5],
        value
      )
    }
  }
}
</script>
