<template>
  <!-- 設定種類 -->
  <div class="menuFloor">
    <ul class="menuFloor_inner">
    <li v-for="(tab, index) in tabLabels" :key="index" class="menuFloor_list">
      <button type="button" value="index" :class="index == selectedTab ? 'tab_selected' : ''" class="naturalbutton" style="margin: 5px 5px 5px 5px;" @click="changeTab(index)">{{tab}}</button>
    </li>
    </ul>
  </div>

  <div v-if="selectedTab === 1" class="lastCompornents">
    <AdminSettingConfigComponent />
  </div>
  <div v-if="selectedTab === 0" class="lastCompornents">
    <AdminSettingRoomComponent />
  </div>
  <div v-if="selectedTab === 2" class="lastCompornents">
    <AdminChangePasswordComponent />
  </div>
</template>

<script>
import AdminSettingRoomComponent from '@/components/AdminSettingRoomComponent'
import AdminSettingConfigComponent from '@/components/AdminSettingConfigComponent'
import AdminChangePasswordComponent from '@/components/AdminChangePasswordComponent'
export default {
  name: 'AdminSettingComponent',
  components: {
    AdminSettingRoomComponent,
    AdminSettingConfigComponent,
    AdminChangePasswordComponent
  },
  data: function () {
    return {
      tabLabels: ['個室設定', '全体設定', 'パスワード変更'],
      selectedTab: 0
    }
  },
  mounted () {
    // 画面初期表示時に実行
  },
  methods: {
    // 選択中タブの変更
    changeTab: function (value) {
      this.selectedTab = value
    }
  }
}
</script>
