<template>
  <div class="container">
    <!-- リロード -->
    <button type="button" @click="clickReload" value="setting" class="buttonBasic boxRight" id="re" title="再読み込み">
      <img :src="reload" alt="再読み込み" width="30" style="margin-top: -6px;">
      <span>再読み込み</span>
    </button>

    <!-- 処理中イメージ表示 -->
    <div v-if="loading === 1" style="text-align: center;">
      <img :src="nowLoading" alt="処理中イメージ" width="300">
    </div>

    <section v-if="loading === 0" width="100%" style="margin-top: 20px;">
      <div v-if="configData[configParamNames[0]]" style="text-align:left;">
        <h2 v-if="configData[configParamNames[0]]" class="ttl_box">{{configData[configParamNames[0]].label}}</h2>

        <div v-if="setMsg != ''" class="messageBoxDone" style="margin-top: 10px;">
          <label>{{setMsg}}</label>
        </div>

        <p v-if="showConfirmations === 0 && configData[configParamNames[0]]">現在、オフになっています。<br>{{configData[configParamNames[0]].remarks}}</p>
        <p v-if="showConfirmations === 1 && configData[configParamNames[0]]">現在、オンになっています。<br>{{configData[configParamNames[0]].remarks}}</p>
        <div>
          <button v-if="showConfirmations === 0" @click="setShowConfirmations()" class="buttonPost">オンにする</button>
          <button v-if="showConfirmations === 1" @click="setShowConfirmations()" class="buttonPost">オフにする</button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import reImage from '@/assets/re.png'
import loadingImage from '@/assets/nowLoading.gif'
import {
  apiUrls,
  cookieParamNameLists,
  apiHeaderName
} from '@/publicVariableList.js'
export default {
  name: 'AdminSettingConfigComponent',
  data: function () {
    return {
      apiUrl: apiUrls, // APIのURL
      reload: reImage, // 再読み込みアイコン画像
      nowLoading: loadingImage, // ローディングイメージ
      setMsg: '', // ステータス更新時のメッセージ
      showConfirmations: 0, // 個室の個別表示フラグ 0は非表示、1は表示
      configParamNames: cookieParamNameLists, // クッキーのkey名
      configData: [], // 全体設定
      loading: 0, // 処理中フラグ 0は非表示、1は表示
      apiHeaderStr: '' // API実行時に追加するヘッダーの中身
    }
  },
  mounted () {
    // 画面初期表示時に実行
    this.apiHeaderStr = btoa('AdminSettingConfig')
    this.getConfig()
  },
  methods: {
    // 全体設定を取得
    getConfig: function () {
      const url = this.apiUrl.getConfig
      const customHeader = { key: apiHeaderName, value: this.apiHeaderStr }
      function fetchData (url, customHeader) {
        return new Promise((resolve, reject) => {
          const req = new XMLHttpRequest()
          req.open('GET', url)
          req.setRequestHeader(customHeader.key, customHeader.value)
          req.onload = function () {
            if (req.status === 200) {
              const responseData = JSON.parse(req.responseText)
              resolve(responseData)
            } else {
              console.error('Request failed. Status:', req.status)
              reject(new Error())
            }
          }
          req.send()
        })
      }
      const promises = [fetchData(url, customHeader)]
      Promise.all(promises).then(data => {
        this.makeConfig(data[0])
      }).catch(error => { console.error('Error:', error) })
    },
    // 全体設定作成
    makeConfig: function (data) {
      this.configData = {}
      for (var i = 0; i < data.length; i++) {
        // key_labelが"showConfirmations"なら変数にセット
        if (data[i].key_label === this.configParamNames[0]) {
          this.showConfirmations = parseInt(data[i].status_value)
          this.configData[this.configParamNames[0]] = data[i]
        }
      }
    },
    // 個別表示のオン/オフ
    setShowConfirmations: function () {
      this.loading = 1
      this.setMsg = ''
      var valueLabel = 'オフ'
      var dataId = this.configData[this.configParamNames[0]].data_id
      var value = 0
      if (this.showConfirmations === 0) {
        value = 1
        valueLabel = 'オン'
      } else {
        value = 0
        valueLabel = 'オフ'
      }
      const params = {
        data_id: dataId,
        status: String(value)
      }
      const url = this.apiUrl.changeConfig
      const customHeader = { key: apiHeaderName, value: this.apiHeaderStr }
      function fetchData (url, customHeader) {
        return new Promise((resolve, reject) => {
          const req = new XMLHttpRequest()
          req.open('POST', url)
          req.setRequestHeader(customHeader.key, customHeader.value)
          req.onload = function () {
            if (req.status >= 200 && req.status < 300) {
              const responseData = req.responseText
              resolve(responseData)
            } else {
              console.error('Request failed. Status:', req.status)
              reject(new Error())
            }
          }
          req.send(JSON.stringify(params))
        })
      }
      const promises = [fetchData(url, customHeader)]
      Promise.all(promises).then(data => {
        this.showConfirmations = value
        this.configData[this.configParamNames[0]].status_value = value
        this.setMsg = this.configData[this.configParamNames[0]].label + 'を' + valueLabel + 'にしました'
        this.loading = 0
      }).catch(error => { console.error('Error:', error) })
    },

    // リロードボタン
    clickReload: function () {
      this.setMsg = ''
      this.getConfig()
    }
  }
}
</script>
