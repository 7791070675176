<!-- src/views/Confirmation.vue -->
<template>
  <div class="in_houce">
    <div>
      <!-- <h1>空室確認システム</h1> -->
      <ConfirmationComponent />
    </div>
  </div>
</template>

<script>
import ConfirmationComponent from '@/components/ConfirmationComponent'
export default {
  name: 'ConfirmationPage',
  components: {
    ConfirmationComponent
  }
}
</script>
