<template>
  <!-- 清掃表示用画面 -->
  <div class="container" style="text-align: center;">
    <button type="button" @click="clickReload" value="setting" class="buttonBasic boxRight" id="re" title="再読み込み">
      <img :src="reload" alt="再読み込み" width="30" style="margin-top: -6px;">
      <span>再読み込み</span>
    </button>
    <section>
      <h2 class="ttl_line" style="text-align: left;">清掃用メニュー</h2>

      <div v-if="setMsg != ''" class="messageBoxDone">
        <label>{{setMsg}}</label>
      </div>

      <!-- 処理中イメージ表示 -->
      <div v-if="loading === 1" class="loading">
        <img :src="nowLoading" alt="処理中イメージ" width="300">
      </div>

      <div v-if="loading === 0">
        <div v-for="(floor, index) in floorOption" :key="index" style="margin: 2px 0px 2px 0px;">
          <div v-if="showCleaningBtn[index] === 1" class="menuFloor" style="margin: 0px;">
            <ul class="menuFloor_inner">
              <label>{{floor}}の清掃</label>
              <li class="menuFloor_list">
                <button type="button" v-if="nowCleanings[index] == 0" @click="clickCleaningStartStop(index)" class="menuFloor_list">開始</button>
                <button type="button" v-if="nowCleanings[index] == 1" @click="clickCleaningStartStop(index)" class="menuFloor_list">終了</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import reImage from '@/assets/re.png'
import loadingImage from '@/assets/nowLoading.gif'
import {
  apiUrls,
  apiHeaderName
} from '@/publicVariableList.js'
export default {
  name: 'AdminCleaningComponent',
  data: function () {
    return {
      apiUrl: apiUrls, // APIのURL
      reload: reImage, // 再読み込みアイコン画像
      nowLoading: loadingImage, // ローディングイメージ
      floorOption: [], // 階数選択肢のlabel(selectbox用)
      floorData: [], // 階数選択肢の全データ
      kindsOption: [], // 種類選択肢のlabel(selectbox用)
      kindsImage: [], // 種類選択肢の画像リンク
      kindsData: [], // 種類選択肢の全データ
      allRoom: [], // 個室データ
      selectedFloor: '', // 階数の選択結果
      selectedKinds: '', // 種類の選択結果
      malfunctionLabel: '故障中', // 故障中に表示する
      nowCleanings: [], // 清掃中フラグ配列 清掃中フラグ 0は使用可能、1清掃中
      showCleaningBtn: [], // 清掃ボタン表示フラグ配列 フラグ 0は非表示、1は表示
      showErrors: [], // 検索結果０件時のエラー表示フラグ配列 0はエラー無、1はエラー有
      setMsg: '', // ステータス更新時のメッセージ
      loading: 0, // 処理中フラグ 0は非表示、1は表示
      imgUrl: '', // s3の画像表示用URL
      apiHeaderStr: '' // API実行時に追加するヘッダーの中身
    }
  },
  mounted () {
    // 画面初期表示時に実行
    this.apiHeaderStr = btoa('AdminCleaning')
    this.imgUrl = location.protocol + '//' + location.host
    this.getOptions()
  },
  methods: {
    // 階数とトイレ種類の選択肢を取得
    getOptions: function () {
      // const url = 'https://eb9b1gt3ri.execute-api.ap-northeast-1.amazonaws.com/dev/private_room_confirmation_get_option_resource'
      const url = this.apiUrl.getOptions
      const customHeader = { key: apiHeaderName, value: this.apiHeaderStr }
      function fetchData (url, customHeader) {
        return new Promise((resolve, reject) => {
          const req = new XMLHttpRequest()
          req.open('GET', url)
          req.setRequestHeader(customHeader.key, customHeader.value)
          req.onload = function () {
            if (req.status === 200) {
              const responseData = JSON.parse(req.responseText)
              resolve(responseData)
            } else {
              console.error('Request failed. Status:', req.status)
              reject(new Error())
            }
          }
          req.send()
        })
      }
      const promises = [fetchData(url, customHeader)]
      Promise.all(promises).then(data => {
        this.makeFloorOption(data[0].floorData)
        this.makeKindsOption(data[0].kindsData)
        // 個室データ取得
        this.getAllData()
      }).catch(error => { console.error('Error:', error) })
    },
    // 階数選択肢を作成
    makeFloorOption: function (data) {
      var floorTmp = []
      for (var i = 0; i < data.length; i++) {
        floorTmp[i] = data[i].label
      }
      this.floorData = data
      this.floorOption = floorTmp
    },
    // 種類選択肢を作成
    makeKindsOption: function (data) {
      var kindsTmp = []
      var imgTmp = []
      for (var i = 0; i < data.length; i++) {
        kindsTmp[i] = data[i].label
        imgTmp[i] = data[i].img
      }
      this.kindsData = data
      this.kindsOption = kindsTmp
      this.kindsImage = imgTmp
    },

    // 全データ取得
    getAllData: function () {
      this.allRoom = []
      this.nowCleanings = []
      this.showCleaningBtn = []
      this.showErrors = []
      for (var i = 0; i < (this.floorOption).length; i++) {
        this.allRoom[i] = []
        this.nowCleanings[i] = 0
        this.showCleaningBtn[i] = 0
        this.showErrors[i] = []
        for (var j = 0; j < (this.kindsOption).length; j++) {
          this.allRoom[i][j] = []
          this.showErrors[i][j] = 0
          this.getAllRoom(this.floorOption[i], this.kindsOption[j], i, j)
        }
      }
      // console.log(this.allRoom)
    },

    // 個室データ取得
    getAllRoom: function (selectedFloor, selectedKinds, i, j) {
      const floorId = this.searchOptionId(this.floorData, selectedFloor)
      const kindsId = this.searchOptionId(this.kindsData, selectedKinds)
      const param = '?floor=' + floorId + '&kinds=' + kindsId
      // データ取得
      // const url = 'https://eb9b1gt3ri.execute-api.ap-northeast-1.amazonaws.com/dev/private_room_confirmation_get_room_resource'
      const url = this.apiUrl.getAllRoom
      const customHeader = { key: apiHeaderName, value: this.apiHeaderStr }
      function fetchData (url, customHeader) {
        return new Promise((resolve, reject) => {
          const req = new XMLHttpRequest()
          req.open('GET', url + param)
          req.setRequestHeader(customHeader.key, customHeader.value)
          req.onload = function () {
            if (req.status === 200) {
              const responseData = JSON.parse(req.responseText)
              resolve(responseData)
            } else {
              console.error('Request failed. Status:', req.status)
              reject(new Error())
            }
          }
          req.send()
        })
      }
      const promises = [fetchData(url, customHeader)]
      Promise.all(promises).then(data => {
        this.allRoom[i][j] = data[0]
        if ((this.allRoom[i][j]).length === 0) {
          this.showErrors[i][j] = 1
        } else {
          // 清掃中フラグ
          this.setCleaningFlg(this.allRoom[i][j], i)
          this.showCleaningBtn[i] = 1
        }
      }).catch(error => { console.error('Error:', error) })
    },
    searchOptionId: function (data, label) {
      var id = ''
      for (var i = 0; i < data.length; i++) {
        if (data[i].label === label) {
          id = data[i].data_id
        }
      }
      return id
    },
    // 個室データに対して清掃中かどうかの判定
    setCleaningFlg (privateRoom, i) {
      // 1件でも清掃フラグがあれば清掃中にする
      var Cleaning = 0
      var prLength = privateRoom.length
      if (prLength >= 1) {
        for (var j = 0; j < prLength; j++) {
          if (privateRoom[j].cleaning === true) {
            Cleaning = 1
          }
        }
      }
      this.nowCleanings[i] = Cleaning
    },

    // リロードボタン
    clickReload: function () {
      this.setMsg = ''
      this.getOptions()
    },

    // 清掃開始、終了ボタン
    clickCleaningStartStop (i) {
      this.loading = 1
      this.setMsg = ''
      this.selectedFloor = this.floorOption[i]
      var setStatus = ''
      var cleaning = false
      var nowCleaningSet = 0
      if (this.nowCleanings[i] === 0) {
        cleaning = true
        nowCleaningSet = 1
        setStatus = '清掃を開始'
      } else {
        cleaning = false
        nowCleaningSet = 0
        setStatus = '清掃を終了'
      }
      const floorId = this.searchOptionId(this.floorData, this.selectedFloor)
      const params = {
        floor: floorId,
        status: cleaning,
        cleaning_group: String(floorId)
      }
      // const url = 'https://eb9b1gt3ri.execute-api.ap-northeast-1.amazonaws.com/dev/private_room_confirmation_change_status_cleaning_resource'
      const url = this.apiUrl.cleaning
      const customHeader = { key: apiHeaderName, value: this.apiHeaderStr }
      function fetchData (url, customHeader) {
        return new Promise((resolve, reject) => {
          const req = new XMLHttpRequest()
          req.open('POST', url)
          req.setRequestHeader(customHeader.key, customHeader.value)
          req.onload = function () {
            if (req.status >= 200 && req.status < 300) {
              const responseData = JSON.parse(req.responseText)
              resolve(responseData)
            } else {
              console.error('Request failed. Status:', req.status)
              reject(new Error())
            }
          }
          req.send(JSON.stringify(params))
        })
      }
      const promises = [fetchData(url, customHeader)]
      Promise.all(promises).then(data => {
        function getAllData () {
          this.getAllData()
        }
        const promisesGetData = [getAllData]
        Promise.all(promisesGetData).then(data => {
          this.nowCleanings[i] = nowCleaningSet
          this.setMsg = '「' + this.selectedFloor + '」の' + setStatus + 'しました'
          this.loading = 0
        }).catch(error => { console.error('Error:', error) })
      }).catch(error => { console.error('Error:', error) })
    }
  }
}
</script>
