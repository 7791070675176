<template>
  <!-- パスワード変更 -->
  <div class="container">
    <!-- 処理中イメージ表示 -->
    <div v-if="loading === 1" class="loading" style="text-align: center;">
      <img :src="nowLoading" alt="処理中イメージ" width="300">
    </div>

    <section style="margin-top: 20px;">
      <h2 class="ttl_box" style="text-align: left;">パスワード変更</h2>

    <!-- エラーメッセージ -->
    <div v-if="showPassError === 1" class="messageBoxErrors" style="margin-bottom: 10px;">
      <label>{{errorMsg}}</label>
    </div>
    <!-- 完了メッセージ -->
    <div v-if="showPassDone === 1" class="messageBoxDone">
      <label>{{doneMsg}}</label>
    </div>

      <div v-if="loading === 0">
        <div>
          <p>
            清掃画面から設定を開く際に使用するパスワードを変更します。<br>
            パスワードは英数字の組み合わせで10文字以上にし、英字は必ず大文字と小文字の両方を使用してください。<br>
            使用可能な記号は「- _ !」です。
          </p>
          <table class="passwordTable">
            <tr>
              <th class="passwordTh"><label>現在のパスワード</label></th>
              <td><input type="password" v-model="nowPasswordText" @keyup.enter="handleEnterKey()" placeholder="現在のパスワード"></td>
            </tr>
            <tr>
              <th class="passwordTh"><label>新しいパスワード</label></th>
              <td><input type="password" v-model="newPasswordText" @keyup.enter="handleEnterKey()" placeholder="新しいパスワード"></td>
            </tr>
            <tr>
              <th class="passwordTh"><label>確認用新パスワード</label></th>
              <td><input type="password" v-model="checkPasswordText" @keyup.enter="handleEnterKey()" placeholder="確認用新パスワード"></td>
            </tr>
          </table>
        </div>
        <div>
          <button class="buttonPost" @click="clickSend()">送信</button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import loadingImage from '@/assets/nowLoading.gif'
import {
  apiUrls,
  apiHeaderName
} from '@/publicVariableList.js'
export default {
  name: 'AdminPasswordComponent',
  data: function () {
    return {
      apiUrl: apiUrls, // APIのURL
      nowLoading: loadingImage, // ローディングイメージ
      nowPasswordText: '', // 現在のパスワード入力値
      newPasswordText: '', // 新パスワード入力値
      checkPasswordText: '', // 確認用パスワード入力値
      showPassError: 0, // パスワード変更失敗エラーフラグ ０件時のエラー表示フラグ 0はエラー無、1はエラー有
      errorMsg: '', // エラー時のメッセージ
      showPassDone: 0, // パスワード変更完了フラグ 0未完了、1は完了
      doneMsg: '', // 処理完了メッセージ
      loading: 0, // 処理中フラグ 0は非表示、1は表示
      apiHeaderStr: '' // API実行時に追加するヘッダーの中身
    }
  },
  mounted () {
    // 画面初期表示時に実行
    this.apiHeaderStr = btoa('AdminChangePassword')
  },
  methods: {
    // 送信ボタンクリック
    clickSend: function () {
      this.showPassError = 0
      this.errorMsg = ''
      this.showPassDone = 0
      this.doneMsg = ''
      if (this.nowPasswordText === '' || this.newPasswordText === '' || this.checkPasswordText === '') {
        this.showPassError = 1
        this.errorMsg = '未入力の項目があります'
      } else if (this.newPasswordText !== this.checkPasswordText) {
        this.showPassError = 1
        this.errorMsg = '新パスワードと確認用パスワードが不一致です'
      } else if (this.passwordsValidation(this.newPasswordText) === false) {
        this.showPassError = 1
        this.errorMsg = '新パスワードが不正です'
      } else {
        this.sendPassword()
      }
    },
    // バリデーション
    passwordsValidation: function (password) {
      const isLengthValid = password.length >= 10
      const hasValidCharacters = /^[a-zA-Z0-9-_!]+$/.test(password)
      const isAllHalfWidth = /^[\x20-\x7E]+$/.test(password)
      const hasCaseInsensitive = /^(?=.*[a-z])(?=.*[A-Z])/.test(password)
      return (
        isLengthValid &&
        hasValidCharacters &&
        isAllHalfWidth &&
        hasCaseInsensitive
      )
    },
    // パスワード送信
    sendPassword: function () {
      this.loading = 1
      const nowPassword = this.hashString(this.nowPasswordText)
      const newPassword = this.hashString(this.newPasswordText)
      const params = {
        nowPass: nowPassword,
        newPass: newPassword
      }
      const url = this.apiUrl.changePass
      const customHeader = { key: apiHeaderName, value: this.apiHeaderStr }
      function fetchData (url, customHeader) {
        return new Promise((resolve, reject) => {
          const req = new XMLHttpRequest()
          req.open('POST', url)
          req.setRequestHeader(customHeader.key, customHeader.value)
          req.onload = function () {
            if (req.status >= 200 && req.status < 300) {
              const responseData = JSON.parse(req.responseText)
              resolve(responseData)
            } else {
              console.error('Request failed. Status:', req.status)
              reject(new Error())
            }
          }
          req.send(JSON.stringify(params))
        })
      }
      const promises = [fetchData(url, customHeader)]
      Promise.all(promises).then(data => {
        this.showPassError = data[0].err
        if (data[0].err === 1) {
          this.errorMsg = data[0].msg
        } else {
          this.showPassDone = 1
          this.doneMsg = data[0].msg
          this.nowPasswordText = ''
          this.newPasswordText = ''
          this.checkPasswordText = ''
        }
        this.loading = 0
      }).catch(error => {
        this.showPassError = 1
        this.loading = 0
        console.error('Error:', error)
      })
    },
    // ハッシュ
    hashString: function (str) {
      let hash = 0
      for (var i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i)
        hash = (hash << 5) - hash + char
      }
      return hash.toString()
    },
    // エンターキー押下で実行
    handleEnterKey: function () {
      this.clickSend()
    }
  }
}
</script>
