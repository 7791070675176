<!-- src/views/Confirmation.vue -->
<template>
  <div>
    <SignagePageComponent />
  </div>
</template>

<script>
import SignagePageComponent from '@/components/SignagePageComponent'
export default {
  name: 'SignagePage',
  components: {
    SignagePageComponent
  }
}
</script>
<style>
@import "../css/pages/type.css";
@import "../css/pages/floor.css";
</style>
<link href="../css/pages/type.css" rel="stylesheet">
<link href="../css/pages/floor.css" rel="stylesheet">
