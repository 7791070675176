<!-- src/App.vue -->
<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/admin-page" style="margin: 0px 10px 0px 10px;">Admin</router-link>
      <router-link to="/confirmation-page" style="margin: 0px 10px 0px 10px;">Confirmation</router-link>
    </nav> -->
    <router-view />
    <!-- <div class="container">
      <section>
        <h2 class="ttl_box">リンク一覧</h2>
        <div style="margin-bottom: 10px;"><router-link to="/confirmation-page">空室確認画面</router-link><br></div>
        <div style="margin-bottom: 10px;"><router-link to="/admin-page">総務用画面</router-link><br></div>
      </section>
    </div> -->
  </div>
  <footer  v-if="showFooter" class="footer" style="position: fixed; bottom: 0;">
    <small>&copy; 株式会社広島情報シンフォニー</small>
  </footer>
</template>
<script>
// import { computed } from 'vue'
export default {
  name: 'App',
  data: function () {
    return {}
  },
  mounted () {},
  computed: {
    showFooter () {
      // サイネージページの時はフッタ―を表示しない
      const noFooterPages = ['/signage-page']
      return !noFooterPages.includes(this.$route.path)
    }
  }
}
</script>
<style>
@import "./css/main.css";
</style>
<link href="css/main.css" rel="stylesheet">
