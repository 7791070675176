<template>
  <!-- パスワード認証 -->
  <div v-if="askForPassword === 1" class="container" style="text-align: center;">
    <section>
      <h2 class="ttl_line" style="text-align: left;">パスワード認証</h2>
      <!-- 処理中イメージ表示 -->
      <div v-if="loading === 1" class="loading">
        <img :src="nowLoading" alt="処理中イメージ" width="300">
      </div>
      <div v-if="loading === 0">
        <!-- エラーメッセージ -->
        <div v-if="showPassError === 1" class="messageBoxErrors">
          <label>パスワードが不正です</label>
        </div>
        <div style="margin: 20px; 0px 0px 0px">
          <label style="margin-right: 10px;">パスワード</label>
          <input type="password" v-model="PasswordText" @keyup.enter="handleEnterKey()" placeholder="パスワード">
        </div>
        <div style="margin-top: 10px; text-align: center;">
          <button class="buttonPost" @click="sendPassword()">送信</button>
        </div>
      </div>
    </section>
  </div>
  <!-- 設定画面 -->
  <div v-if="askForPassword === 0">
    <AdminSettingComponent />
  </div>
</template>

<script>
import AdminSettingComponent from '@/components/AdminSettingComponent'
import loadingImage from '@/assets/nowLoading.gif'
import {
  apiUrls,
  apiHeaderName
} from '@/publicVariableList.js'
export default {
  name: 'AdminPasswordComponent',
  components: {
    AdminSettingComponent
  },
  data: function () {
    return {
      apiUrl: apiUrls, // APIのURL
      nowLoading: loadingImage, // ローディングイメージ
      askForPassword: 1, // パスワード入力画面表示フラグ 0は非表示、1は表示
      PasswordText: '', // パスワード入力値
      showPassError: 0, // パスワード認証失敗エラーフラグ ０件時のエラー表示フラグ 0はエラー無、1はエラー有
      loading: 0, // 処理中フラグ 0は非表示、1は表示
      apiHeaderStr: '' // API実行時に追加するヘッダーの中身
    }
  },
  mounted () {
    // 画面初期表示時に実行
    this.apiHeaderStr = btoa('AdminPassword')
  },
  methods: {
    // パスワード送信
    sendPassword: function () {
      this.loading = 1
      this.showPassError = 0
      var str = this.hashString(this.PasswordText)
      // console.log(str)
      const params = {
        pass: str
      }
      // const url = 'https://eb9b1gt3ri.execute-api.ap-northeast-1.amazonaws.com/dev/private_room_confirmation_password_authentication_resource'
      const url = this.apiUrl.sendPassword
      const customHeader = { key: apiHeaderName, value: this.apiHeaderStr }
      function fetchData (url, customHeader) {
        return new Promise((resolve, reject) => {
          const req = new XMLHttpRequest()
          req.open('POST', url)
          req.setRequestHeader(customHeader.key, customHeader.value)
          req.onload = function () {
            if (req.status >= 200 && req.status < 300) {
              const responseData = req.responseText
              resolve(responseData)
            } else {
              console.error('Request failed. Status:', req.status)
              reject(new Error())
            }
          }
          req.send(JSON.stringify(params))
        })
      }
      const promises = [fetchData(url, customHeader)]
      Promise.all(promises).then(data => {
        if (data[0] === 'true') {
          this.askForPassword = 0
        } else {
          this.showPassError = 1
        }
        this.loading = 0
      }).catch(error => {
        this.showPassError = 1
        this.loading = 0
        console.error('Error:', error)
      })
      this.PasswordText = ''
    },
    // ハッシュ
    hashString: function (str) {
      let hash = 0
      for (var i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i)
        hash = (hash << 5) - hash + char
      }
      return hash.toString()
    },
    // エンターキー押下で実行
    handleEnterKey: function () {
      this.sendPassword()
    }
  }
}
</script>
