<template>
  <!-- 個室種類選択(項目数に上限なし) -->
  <div class="menuCategory">
    <p style="margin-bottom: -10px; text-align: center;">条件指定表示</p>
    <ul class="menuCategory_inner">
      <li v-for="(kinds, index) in kindsOption" :key="index" class="menuCategory_list">
        <button type="button" value="kinds" :class="kinds == selectedKinds ? 'tab_selected' : ''" @click="clickKinds(kinds, index)">
          <span class="material-symbols-outlined">
            <img :src="imgUrl + kindsImage[index]" :alt="kinds" class="icon_set" style="margin-top: -26px;">
          </span>
          <span style="margin-top: 6px;">{{kinds}}</span>
        </button>
      </li>
    </ul>
  </div>
  <!-- 階数選択(項目数に上限なし) -->
  <div class="menuFloor">
    <ul class="menuFloor_inner">
      <li v-for="(floor, index) in floorOption" :key="index" class="menuFloor_list">
        <button type="button" value="floor" :class="floor == selectedFloor ? 'tab_selected' : ''" @click="clickFloor(floor, index)">{{floor}}</button>
      </li>
    </ul>
  </div>

  <div class="container">
    <!-- リロード -->
    <button type="button" @click="clickReload" value="setting" class="buttonBasic boxRight" id="re" title="再読み込み">
      <img :src="reload" alt="再読み込み" width="30" style="margin-top: -6px;">
      <span>再読み込み</span>
    </button>
    <section class="roomWrap">
      <h2 v-if="selectedFloor != '' && selectedKinds != ''" class="ttl_line">{{selectedFloor}}／{{selectedKinds}}</h2>

      <!-- 未選択時メッセージ -->
      <div v-if="selectedFloor == '' || selectedKinds == ''" style="margin: 2% 0% 0% 0%; text-align:center;">
        <label v-if="floorOption.length !== 0 && kindsOption.length !== 0">{{msgBox.noSelected}}</label>
      </div>
      <!-- 0件時エラーメッセージ -->
      <div v-if="showError === 1" class="messageBoxErrors">
        <label>{{msgBox.error}}</label>
      </div>
      <!-- 満員・清掃中の帯 -->
      <div v-if="allUsing === 1 && nowCleaning === 0" class="messageBox">
        <label>{{msgBox.allUsing}}</label>
      </div>
      <div v-if="nowCleaning === 1" class="messageBox">
        <label>{{msgBox.nowCleaning}}</label>
      </div>
      <!-- 個別表示オフ時に使用可能な個室をメッセージ表示 -->
      <div v-if="privateRoom.length >= 1 && showConfirmations === 0 && allUsing === 0 && nowCleaning === 0" class="messageBoxUseOk">
        <label>{{msgBox.useOk.front + unused + msgBox.useOk.back}}</label>
      </div>

      <!-- 個別表示オフイメージ -->
      <div v-show="showConfirmations === 0" style="margin: 30px 0px 0px 0px; text-align:center;">
        <!-- 使用可能 -->
        <div v-if="privateRoom.length >= 1 && allUsing === 0 && nowCleaning === 0" class="status_unused">
          <div class="roomBox_icon" style="margin: 10px 0px 20px 0px;">
            <span class="material-symbols-outlined">
              <img :src="imgUrl + selectedKindsImage" :alt="使用可能" class="icon_set">
            </span>
          </div>
        </div>
        <!-- 満室 -->
        <div v-if="privateRoom.length >= 1 && allUsing === 1 && nowCleaning === 0" class="status_using">
          <div class="roomBox_icon" style="margin: 10px 0px 20px 0px;">
            <span class="material-symbols-outlined">
              <img :src="imgUrl + selectedKindsImage" :alt="満室" class="icon_set">
            </span>
          </div>
        </div>
        <!-- 清掃中 -->
        <div v-if="privateRoom.length >= 1 && nowCleaning === 1" class="status_clean">
          <div class="roomBox_icon" style="margin: 10px 0px 20px 0px;">
            <span class="material-symbols-outlined">
              <img :src="imgUrl + selectedKindsImage" :alt="清掃中" class="icon_set">
            </span>
          </div>
        </div>
      </div>

      <!-- 個別表示オンイメージ -->
      <ul v-show="showConfirmations === 1 && privateRoom.length >= 1" class="roomBox">
        <li v-for="(pr, i) in privateRoom" :key="i" class="roomBox_item" style="text-align: center; align-items: center;">
          <!-- 未使用・使用中 -->
          <label v-if="pr.malfunction == false && pr.cleaning == false" :class="pr.now_using == 0 ? ' status_unused': 'status_using'" style="text-align: center; align-items: center;">
            <div class="roomBox_icon" style="margin: 10px 0px 20px 0px;">
              <span class="material-symbols-outlined">
                <img :src="imgUrl + pr.img" :alt="pr.label" class="icon_set">
              </span>
            </div>
            <div class="roomBox_name"><p>{{pr.label}}</p></div>
            <div class="roomBox_status">
              <p :class="pr.now_using == 0 ? '' : 'status_using'">{{usingLabel[pr.now_using]}}</p>
            </div>
          </label>
          <!-- 故障中 -->
          <label v-if="pr.malfunction == true && pr.cleaning == false" :class="pr.malfunction == true ? 'status_repair': ''">
            <div class="roomBox_icon" style="margin: 10px 0px 20px 0px;">
              <span class="material-symbols-outlined">
                <img :src="imgUrl + pr.img" :alt="pr.label" class="icon_set">
              </span>
            </div>
            <div class="roomBox_name"><p>{{pr.label}}</p></div>
            <div class="roomBox_status">
              <p>{{malfunctionLabel}}</p>
            </div>
          </label>
          <!-- 清掃中 -->
          <label v-if="pr.cleaning == true" class="status_clean">
            <div class="roomBox_icon" style="margin: 10px 0px 20px 0px;">
              <span class="material-symbols-outlined">
                <img :src="imgUrl + pr.img" :alt="pr.label" class="icon_set">
              </span>
            </div>
            <div class="roomBox_name"><p>{{pr.label}}</p></div>
            <div class="roomBox_status">
              <p>{{cleaningLabel}}</p>
            </div>
          </label>
          <!-- <div><a href="#" @click="testUsing(i)">センサー</a></div> -->
        </li>
      </ul>
      <div class="showLastDateTime">{{lastDateTime}}</div>
    </section>
  </div>

  <!-- 個人設定モード -->
  <div class="container">
    <section>
      <h2 class="ttl_box">自動再読み込み</h2>
      <p>自動で再読み込みを行う間隔を設定します。</p>
      <select v-model="selectedAutoReload" @change="setAutoReload()" class="re_select">
        <option v-for="(select, i) in autoReloadOption" :value="i" :key="i">{{select.label}}</option>
      </select>
    </section>
  </div>
</template>

<script>
import reImage from '@/assets/re.png'
import {
  apiUrls,
  autoReloadOptions,
  cookieParamNameLists,
  storagePeriodNum,
  demoUrl,
  apiHeaderName
} from '@/publicVariableList.js'
export default {
  name: 'ConfirmationSomeComponent',
  data: function () {
    return {
      apiUrl: apiUrls, // APIのURL
      reload: reImage, // 再読み込みアイコン画像
      msgBox: {
        noSelected: '画面上部のボタンから種類とフロアを選択してください',
        allUsing: '現在満室です',
        nowCleaning: '現在清掃中です',
        useOk: { front: '使用可能な個室が、', back: 'つあります' },
        error: '表示可能な個室が見つかりませんでした'
      },
      floorOption: [], // 階数選択肢のlabel(selectbox用)
      floorData: [], // 階数選択肢の全データ
      kindsOption: [], // 種類選択肢のlabel(selectbox用)
      kindsImage: [], // 種類選択肢の画像リンク
      kindsData: [], // 種類選択肢の全データ
      privateRoom: [], // 個室検索結果
      selectedFloor: '', // 階数の選択結果
      selectedKinds: '', // 種類の選択結果
      selectedKindsImage: '', // 個別表示オフ時のIcon
      usingLabel: { false: '未使用', true: '使用中' }, // 個室に表示する使用状況
      malfunctionLabel: '故障中', // 故障中に表示する
      cleaningLabel: '清掃中', // 清掃中に表示する
      nowCleaning: 0, // 清掃中フラグ 0は使用可能、1清掃中
      allUsing: 0, // 満室フラグ 0は使用可能、1は満室
      showError: 0, // 検索結果０件時のエラー表示フラグ 0はエラー無、1はエラー有
      setMsg: '', // ステータス更新時のメッセージ
      showConfirmations: 0, // 個室の個別表示フラグ 0は非表示、1は表示
      autoReloadOption: autoReloadOptions, // 自動再読み込み選択肢
      selectedAutoReload: 0, // 自動再読み込み選択結果
      unused: 0, // 使用されていない個室のカウント
      storagePeriod: storagePeriodNum, // クッキーの保存期間
      cookieParamNames: cookieParamNameLists, // クッキーのkey名
      lastDateTime: '', // 最終更新日時
      imgUrl: '', // s3の画像表示用URL
      apiHeaderStr: '', // API実行時に追加するヘッダーの中身
      intervalId: null
    }
  },
  mounted () {
    // 画面初期表示時に実行
    this.apiHeaderStr = btoa('ConfirmationSome')
    this.imgUrl = location.protocol + '//' + location.host
    // テスト用
    if (this.imgUrl.includes('localhost')) {
      this.imgUrl = demoUrl
    }
    this.getConfig()
    this.getOptions()
  },
  beforeUnmount () {
    // compornent破棄時に実行
    this.autoReloadStop()
  },
  methods: {
    // 全体設定を取得
    getConfig: function () {
      // const key = 'pMsyModhE22NLnjk34Sc82wjUP5hy9297iSprEKo'
      const url = this.apiUrl.getConfig
      const customHeader = { key: apiHeaderName, value: this.apiHeaderStr }
      function fetchData (url, customHeader) {
        return new Promise((resolve, reject) => {
          const req = new XMLHttpRequest()
          req.open('GET', url)
          req.setRequestHeader(customHeader.key, customHeader.value)
          req.onload = function () {
            // console.log(req)
            if (req.status === 200) {
              const responseData = JSON.parse(req.responseText)
              resolve(responseData)
            } else {
              console.error('Request failed. Status:', req.status)
              reject(new Error())
            }
          }
          req.send()
        })
      }
      const promises = [fetchData(url, customHeader)]
      Promise.all(promises).then(data => {
        this.makeConfig(data[0])
      }).catch(error => { console.error('Error:', error) })
    },
    // 全体設定作成
    makeConfig: function (data) {
      for (var i = 0; i < data.length; i++) {
        // console.log(data[i])
        // key_labelが"showConfirmations"なら変数にセット
        if (data[i].key_label === this.cookieParamNames[0]) {
          this.showConfirmations = parseInt(data[i].status_value)
        }
      }
    },

    // 階数とトイレ種類の選択肢を取得
    getOptions: function () {
      const url = this.apiUrl.getOptions
      const customHeader = { key: apiHeaderName, value: this.apiHeaderStr }
      function fetchData (url, customHeader) {
        return new Promise((resolve, reject) => {
          const req = new XMLHttpRequest()
          req.open('GET', url)
          req.setRequestHeader(customHeader.key, customHeader.value)
          req.onload = function () {
            if (req.status === 200) {
              const responseData = JSON.parse(req.responseText)
              resolve(responseData)
            } else {
              console.error('Request failed. Status:', req.status)
              reject(new Error())
            }
          }
          req.send()
        })
      }
      const promises = [fetchData(url, customHeader)]
      Promise.all(promises).then(data => {
        this.makeFloorOption(data[0].floorData)
        this.makeKindsOption(data[0].kindsData)
        this.showCookie()
        if (this.selectedFloor !== '' && this.selectedKinds !== '') {
          this.reloader()
        }
        this.callAutoReloader()
      }).catch(error => { console.error('Error:', error) })
    },
    // 階数選択肢を作成
    makeFloorOption: function (data) {
      var floorTmp = []
      for (var i = 0; i < data.length; i++) {
        floorTmp[i] = data[i].label
      }
      this.floorData = data
      this.floorOption = floorTmp
    },
    // 種類選択肢を作成
    makeKindsOption: function (data) {
      var kindsTmp = []
      var imgTmp = []
      for (var i = 0; i < data.length; i++) {
        kindsTmp[i] = data[i].label
        imgTmp[i] = data[i].img
      }
      this.kindsData = data
      this.kindsOption = kindsTmp
      this.kindsImage = imgTmp
    },

    // 階数選択
    clickFloor: function (value, i) {
      this.selectedFloor = value
      this.addCookie(
        this.cookieParamNames[2],
        i
      )
      if (this.selectedKinds !== '') {
        this.dlPrivateRoom()
        this.callAutoReloader()
      }
    },

    // 種類選択
    clickKinds: function (value, i) {
      this.selectedKindsImage = this.kindsImage[i]
      this.selectedKinds = value
      this.addCookie(
        this.cookieParamNames[3],
        i
      )
      if (this.selectedFloor !== '') {
        this.dlPrivateRoom()
        this.callAutoReloader()
      }
    },

    // 使用状況データ取得
    dlPrivateRoom: function () {
      // console.log(this.selectedFloor + '/' + this.selectedKinds)
      const floorId = this.searchOptionId(this.floorData, this.selectedFloor)
      const kindsId = this.searchOptionId(this.kindsData, this.selectedKinds)
      const param = '?floor=' + floorId + '&kinds=' + kindsId
      // データ取得
      const url = this.apiUrl.dlPrivateRoom
      const customHeader = { key: apiHeaderName, value: this.apiHeaderStr }
      function fetchData (url, customHeader) {
        return new Promise((resolve, reject) => {
          const req = new XMLHttpRequest()
          req.open('GET', url + param)
          req.setRequestHeader(customHeader.key, customHeader.value)
          req.onload = function () {
            if (req.status === 200) {
              const responseData = JSON.parse(req.responseText)
              resolve(responseData)
            } else {
              console.error('Request failed. Status:', req.status)
              reject(new Error())
            }
          }
          req.send()
        })
      }
      const promises = [fetchData(url, customHeader)]
      Promise.all(promises).then(data => {
        // 自動再読み込み時に画面のちらつきを防ぐため、データが前回と一致しているかを確認
        var updateFlg = this.checkData(this.privateRoom, data[0])
        if (updateFlg === 1) {
          this.showError = 0
          this.allUsing = 0
          this.nowCleaning = 0
          this.privateRoom = []
          this.privateRoom = data[0]
          // console.log(this.privateRoom)
          if ((this.privateRoom).length === 0) {
            this.showError = 1
          } else {
            // 清掃中フラグ、満室フラグ設定
            this.setCleaningFlg(this.privateRoom)
            this.setAllUsingFlg(this.privateRoom)
          }
        }
        this.getLastDateTime()
      }).catch(error => { console.error('Error:', error) })
    },
    searchOptionId: function (data, label) {
      var id = ''
      for (var i = 0; i < data.length; i++) {
        if (data[i].label === label) {
          id = data[i].data_id
        }
      }
      return id
    },
    // 自動再読み込み時に画面のちらつきを防ぐため、データが前回と一致しているかを確認
    checkData: function (nowData, newData) {
      var updateFlg = 0
      var keys = []
      if (nowData.length === 0) {
        updateFlg = 1
      } else if (nowData.length !== newData.length) {
        updateFlg = 1
      } else {
        for (var i = 0; i < nowData.length; i++) {
          keys = Object.keys(nowData[i])
          for (var j = 0; j < keys.length; j++) {
            if (nowData[i][keys[j]] !== newData[i][keys[j]]) {
              updateFlg = 1
            }
          }
        }
      }
      return updateFlg
    },
    // 使用状況データに対して清掃中かどうかの判定
    setCleaningFlg (privateRoom) {
      // 1件でも清掃フラグがあれば清掃中にする
      var Cleaning = 0
      var prLength = privateRoom.length
      if (prLength >= 1) {
        for (var i = 0; i < prLength; i++) {
          if (privateRoom[i].cleaning === true) {
            Cleaning = 1
          }
        }
      }
      this.nowCleaning = Cleaning
    },
    // 使用状況データから満室を設定
    setAllUsingFlg (privateRoom) {
      // 全室仕様中なら満室とする
      var usingCount = 0
      var unusedCount = 0
      var prLength = privateRoom.length
      if (prLength >= 1) {
        for (var i = 0; i < prLength; i++) {
          if ((privateRoom[i].now_using === true) || (privateRoom[i].malfunction === true)) {
            usingCount += 1
          } else {
            unusedCount += 1
          }
        }
      }
      this.unused = unusedCount
      if (usingCount === prLength) {
        this.allUsing = 1
      }
    },

    // リロードボタン
    clickReload: function () {
      location.reload()
    },
    // リロード
    reloader: function () {
      if ((this.selectedFloor !== '') && (this.selectedkinds !== '')) {
        this.dlPrivateRoom()
      }
    },

    // 自動再読み込み設定
    setAutoReload: function () {
      this.addCookie(
        this.cookieParamNames[1],
        this.selectedAutoReload
      )
      this.callAutoReloader()
    },

    // クッキー新規作成
    addCookie: function (key, val) {
      const expirationDate = new Date()
      expirationDate.setDate(expirationDate.getDate() + this.storagePeriod)
      document.cookie = key + '=' + val + '; expires=' + expirationDate.toUTCString()
    },
    // クッキー確認
    showCookie: function () {
      const cookies = document.cookie.split('; ')
      var data = []
      for (const cookie of cookies) {
        // const [key, value] = cookie.split('=')
        data = cookie.split('=')
        // 自動再読み込みンの場合
        if (this.cookieParamNames[1] === data[0]) {
          this.selectedAutoReload = Number(data[1])
        }
        // 階数選択の場合
        if (this.cookieParamNames[2] === data[0]) {
          this.selectedFloor = this.floorOption[data[1]]
        }
        // 個室種類選択の場合
        if (this.cookieParamNames[3] === data[0]) {
          this.selectedKinds = this.kindsOption[data[1]]
          this.selectedKindsImage = this.kindsImage[data[1]]
        }
        // console.log(data[0] + ': ' + data[1])
      }
    },

    // 自動再仰臥実行
    callAutoReloader: function () {
      this.autoReloadStop()
      this.autoReloader()
    },
    // 自動再読み込み
    autoReloader: function () {
      var num = this.autoReloadOption[this.selectedAutoReload].interval
      if (num > 0) {
        this.intervalId = setInterval(() => {
          this.reloader()
        }, num)
      }
    },
    // 自動再読み込み停止
    autoReloadStop: function () {
      clearInterval(this.intervalId)
    },

    // 最終更新日時取得
    getLastDateTime: function () {
      const now = new Date()
      const year = now.getFullYear()
      const month = (now.getMonth() + 1).toString().padStart(2, '0')
      const day = (now.getDate()).toString().padStart(2, '0')
      const hours = (now.getHours()).toString().padStart(2, '0')
      const minutes = (now.getMinutes()).toString().padStart(2, '0')
      const seconds = (now.getSeconds()).toString().padStart(2, '0')
      const str = '最終更新: '

      // フォーマットを整える
      this.lastDateTime = `${str} ${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    },

    // テスト用登録（使用中・未使用）
    testUsing: function (i) {
      var using = false
      if (this.privateRoom[i].now_using === false) {
        using = true
      } else {
        using = false
      }
      const params = {
        data_id: this.privateRoom[i].data_id,
        status: using
      }
      // const url = 'https://eb9b1gt3ri.execute-api.ap-northeast-1.amazonaws.com/dev/private_room_confirmation_change_status_using_resource'
      const url = this.apiUrl.testUsing
      function fetchData (url) {
        return new Promise((resolve, reject) => {
          const req = new XMLHttpRequest()
          req.open('POST', url)
          // req.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
          req.onload = function () {
            if (req.status >= 200 && req.status < 300) {
              const responseData = req.responseText
              resolve(responseData)
            } else {
              console.error('Request failed. Status:', req.status)
              reject(new Error())
            }
          }
          req.send(JSON.stringify(params))
        })
      }
      const promises = [fetchData(url)]
      Promise.all(promises).then(data => {
        this.reloader()
      }).catch(error => { console.error('Error:', error) })
    }
  }
}
</script>
